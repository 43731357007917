/*

 
 */

import React, { useState, useEffect, useCallback } from "react";
import { renderToString } from 'react-dom/server'
import { useQuery, useMutation } from 'react-apollo';
import { useSelector } from 'react-redux';

import JSON5 from 'json5';
import { getCookie, makeAdjustedString, makeDateString, sanitizeRecord } from '../../../util.js';
import { baseRoyaltyStatementObject } from './BaseRoyaltyStatementObject.js';
import { SelectCreator } from "../../../inputs/index.js";
import RoyaltyItemCreator from "./RoyaltyItemCreator.js";
import RoyaltyStatementLineItem from "./RoyaltyStatementLineItem.js"
import RoyaltyStatementSummary from "./RoyaltyStatementSummary.js";
import QUERY_ROYALTYS_FOR_STATEMENT from "../../../graphql/queries/Royaltys/QueryRoyaltysForStatement.js";
import CREATE_ROYALTY_STATEMENT_MUTATION from "../../../graphql/mutations/Royaltys/CreateRoyaltyStatement.js";


import {
  Pane,
  Card,
  Paragraph,
  Popover,
  TextareaField,
  Button,
  Table,
  Tooltip,
  HelpIcon,
  SelectMenu,
  Spinner,
  toaster,
  Switch
} from 'evergreen-ui';

import {
  DateFieldRange
} from "../../../inputs/index.js";
import {NotSeeingItems} from "../../../components/NotSeeingItems.js";

function StatementForm(props) {
  const publisherGlobalData = useSelector((state) => state.publisherGlobalData.value);
  const currPub = useSelector((state) => state.currentpublisher.value);
  const userid = useSelector((state) => state.user.value);



  const currency = publisherGlobalData.currency
    ? publisherGlobalData.currency.symbol
    : "$";


  const [staged, setStaged] = useState("");
  const [email, setEmail] = useState("");
  const [creator, setCreator] = useState({});
  const [royaltys, setRoyaltys] = useState([]);
  const [pubUser, setPubUser] = useState([]);
  const [excluded, setExcluded] = useState([]);
  const [selected, setSelected] = useState([]);
  const [allmode, setAllmode] = useState(false);
  const [statementNote, setstatementNote] = useState("");
  const today = new Date();
  const prevMonth = new Date();
  const initStartDate = makeDateString(new Date(prevMonth.setDate(prevMonth.getDate() - 30)));
  const todayDate = makeDateString(today);
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(todayDate);

  const [createPayment, setCreatePayment] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [fetched, setFetched] = useState([])

  const { loading, error, data, refetch: _refetch } = useQuery(QUERY_ROYALTYS_FOR_STATEMENT, {
    variables: {
      userid: userid,
      publisher: parseInt(props.publisherId),
      creator: creator.id ? creator.id : "",
      startDate: startDate,
      endDate: endDate
    }
  });


  const refetch = useCallback(() => { setTimeout(() => _refetch(), 0) }, [_refetch]);
  const [createRoyaltyStatement, { statementdata }] = useMutation(CREATE_ROYALTY_STATEMENT_MUTATION);

  const handleClearStaged = () => {
    setStaged(baseRoyaltyStatementObject);
    setFetched(false);
  }

  const handleChangeDate = obj => {
    let newDate = makeDateString(obj.val);
    if (obj.key === "startDate") {
      setStartDate(newDate)
    } else {
      setEndDate(newDate)
    }
    setSelected([])
  }

  const formatCreatorAddress = creator => {
    let address = {};
    if (creator.creatoraddressSet.length > 1) {
      address = creator.creatoraddressSet.filter(item => item.primary === true)[0];
    } else {
      address = creator.creatoraddressSet[0];
    }

    let stringAddress = "";
    if (address) {
      let line1 = address.line1 ? `${address.line1}\n` : "";
      let line2 = address.line2 ? `${address.line2}\n` : "";
      let city = address.city ? `${address.city} ` : "";
      let stateOrRegion = address.stateOrRegion ? `${address.stateOrRegion}\n` : "";
      let zipcode = address.zipcode ? `${address.zipcode} ` : "";
      let country = address.country ? `${address.country}\n` : "";
      stringAddress = `${line1}${line2}${city} ${stateOrRegion} ${zipcode}${country}`;
    }

    return address ? stringAddress : "[No address listed]"
  }


  const sendRoyaltyStatement = async (email, creator, address, summary, detail) => {

    const csrftoken = getCookie('csrftoken');
    fetch('/send_royalty_statement/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': csrftoken
      },
      body: JSON.stringify({
        creatorEmail: email,
        pubEmail: pubUser.email,
        userName: pubUser.publisheruserSet[0].firstName,
        creatorId: creator.id,
        pubName: currPub.name,
        sendDate: todayDate,
        pubNote: statementNote,
        address: address,
        summary: summary,
        detail: detail,
      })
    })
      .then(res => res.json())
      .then(data => {
        // TODO catch status: invalid
        toaster.closeAll()
        toaster.success("Successfully emailed royalty statement", { duration: 10 })
        return data;
      }).catch(error => toaster.notify("Error sending statement. Retry or contact developer."))
  }

  const handleCreateRoyaltyStatement = async () => {
    try {
      let summary = renderToString(statementEmailSummary)

      let lineItems = selected.map((lineitem, index) => {
        let statementItem = RoyaltyStatementLineItem({
          key: lineitem.id,
          item: lineitem,
          publisherGlobalData: publisherGlobalData,
          prodTotal: selected.length,
          idx: index + 1,
          startDate: startDate,
          endDate: endDate,
        });
        return renderToString(statementItem);
      });

      lineItems.push(renderToString(royaltyTermDefs))

      let productArr = selected.map(item => {
        let product = JSON5.parse(makeAdjustedString(item.product));
        return product.id
      })

      let creator = staged.creator;
      let address = formatCreatorAddress(creator);
      // create the statement in the DB
      if (sendEmail){
        let sent = await sendRoyaltyStatement(email, creator, address, summary, lineItems.join("\n"));
        console.log(sent)
      }

      let result = await createRoyaltyStatement(
        {
          variables: {
            publisherId: parseInt(props.publisherId),
            creatorId: parseInt(creator.id),
            productIds: JSON.stringify({ productArr }),
            statement: JSON.stringify({
              pubNote: statementNote,
              prodArray: JSON.stringify(selected),
              sentDate: todayDate,
              startDate: startDate,
              endDate: endDate
            })
          }
        });

      toaster.closeAll()
      toaster.success(result.data.createRoyaltyStatement.message, { duration: 10 })
      props.handleToggleStatementMode();
      if (createPayment) {
        props.handleTogglePaymentMode(creator, selected, startDate, endDate);
      }
      handleClearStaged();
      props.handleRefetchRoyaltys();
    }
    catch (error) {
      toaster.closeAll()
      toaster.danger("Error creating royalty statement. Try again or contact developer", { duration: 5 })
      console.log(error.message)

    }

  }

  const handleUpdateStaged = obj => {
    setStaged(oldState => ({ ...oldState, [obj.key]: obj.val }))
  }

  const handleAttachCreator = creatorString => {
    let creator = JSON.parse(creatorString)
    handleUpdateStaged({
      "key": "creator",
      "val": creator
    })
    setCreator(creator)
    setSelected([]);
    setEmail("")
  }

  useEffect(() => {
    if (data) {
      setRoyaltys(data.royaltysforstatement);
      setPubUser(data.user)
    }
  }, [data]);

  const handleAddSelected = item => {
    const newitem = [sanitizeRecord(JSON.parse(JSON.stringify(item)))];

    if (newitem.length) {
      const newSelect = excluded.filter(val => !newitem.find(({ id }) => val.id === id));
      setExcluded(newSelect);
      setSelected(selected.concat(newitem));
    } else {
      setSelected(selected.concat(newitem));

    }

  }

  const handleRemoveSelected = item => {
    // find the item in the current array
    const newarr = selected.filter(obj => obj.id !== item.id);
    setSelected(newarr);
  }

  const handleAddExcluded = item => {
    const newitem = [item];
    setExcluded(excluded.concat(newitem));
  }

  const statementEmailSummary =
    <Pane>
      <Paragraph size={400} marginTop={16}><b>STATEMENT SUMMARY </b></Paragraph>
      <RoyaltyStatementSummary startDate={startDate} endDate={endDate} items={selected} currency={currency} />
      <Paragraph size={400} marginTop={16}><b>STATEMENT DETAILS</b></Paragraph>
    </Pane>

  const royaltyTermDefs =
    <Pane>
      <Paragraph size={400} marginTop={16} ><b>Royalty Term Definitions:</b></Paragraph>
      <Pane>
        <ul>
          <li><Paragraph marginLeft={10} size={300}><b>% of list price:</b> % of title's retail price, no matter how much the publisher earns</Paragraph></li>
          <li><Paragraph marginLeft={10} size={300}><b>% of net receipts:</b> % of publisher's income for title, not taking into account expenses</Paragraph></li>
          <li><Paragraph marginLeft={10} size={300}><b>% of net profits:</b> % of title's sales minus expenses</Paragraph></li>
          <li><Paragraph marginLeft={10} size={300}><b>% of true profits/profit sharing:</b> net profits minus publisher's bottom line percentage</Paragraph></li>
        </ul>
      </Pane></Pane>


  return (
    <Pane position="absolute" width="calc(100vw - 204px)" display="flex" justifyContent="space-around">
      <Pane id="statement-form" elevation={3} backgroundColor="white" padding={16} height="calc(100vh - 32px)"
        width="calc(50vw - 102px)" >
        <Pane id="statement-inputs" display="flex" flexDirection="column">
          <Pane paddingBottom={16} id="heading-buttons" display="flex" justifyContent="space-between">
            <Paragraph size={500} fontWeight="bolder" >New Royalty Statement</Paragraph>
            <Pane display="flex">
              <Button onClick={e => {
                handleClearStaged();
                props.handleToggleStatementMode();
                props.handleClearSelected();
              }}>
                Cancel
              </Button>
              {sendEmail ?
                <Popover
                  content={({ close }) => (
                    <Pane padding={16} >
                      <Paragraph>Are you sure you want to send this royalty statement to {email}?</Paragraph>
                      <Paragraph>This action cannot be undone.</Paragraph>
                      <Pane marginTop={16}>
                        <Button marginRight={16} appearance="primary" intent="success" onClick={e => {
                          handleCreateRoyaltyStatement();
                          if (!createPayment) {
                            props.handleClearSelected();
                          }
                        }}>
                          Yes, Send It!
                        </Button>
                        <Button appearance="primary" intent="danger" onClick={close}>No</Button>
                      </Pane>
                    </Pane>
                  )}
                >
                  <Button marginLeft={10} disabled={!selected.length || !email} className="confirm-button" >CREATE STATEMENT</Button>
                </Popover> :
                <Button marginLeft={10} disabled={!selected.length} className="confirm-button" onClick={e => {
                  handleCreateRoyaltyStatement();
                  if (!createPayment) {
                    props.handleClearSelected();
                  }
                }}>CREATE STATEMENT</Button>

              }
            </Pane>


          </Pane>
          <Pane display="flex" justifyContent="flex-start" alignItems="flex-start">
            <Pane>
              <Paragraph fontWeight="bold" size={300} marginBottom={8} >STATEMENT PERIOD</Paragraph>
              <DateFieldRange
                startDate={startDate}
                endDate={endDate}
                handleChange={handleChangeDate}
              />
            </Pane>
            <Pane marginLeft={32}>
              <Paragraph fontWeight="bold" size={300} marginBottom={8} >OPTIONS</Paragraph>
              <Pane display="flex" marginTop={16} alignItems="center">
                <Switch className="inputCheckbox" checked={createPayment}
                  onChange={e => setCreatePayment(!createPayment)}
                /><Paragraph color="#667b7f" size={300} marginLeft={8}>Generate Payment Record?</Paragraph>
              </Pane>
              <Pane display="flex" marginTop={8} alignItems="center">
                <Switch className="inputCheckbox" checked={sendEmail} onChange={e => setSendEmail(!sendEmail)} />
                <Paragraph color="#667b7f" size={300} marginLeft={8}>Email Statement to Creator?</Paragraph>
              </Pane>
            </Pane>
          </Pane>
          <Paragraph size={300} fontWeight="bold" marginTop={16} marginBottom={8}>CREATOR INFO</Paragraph>
          <Pane id="creator-info" display="flex">
            <Pane>
              <Pane display="flex">
                <Paragraph color="#667b7f" size={300}>Creator </Paragraph>
              </Pane>
              <SelectCreator handleNewCreator={handleAttachCreator} parent="royalty statements"/>
            </Pane>
            <Pane marginLeft={32}>
              <Paragraph color="#667b7f" size={300}>Creator Email</Paragraph>
              <SelectMenu
                hasTitle={false}
                options={staged.creator ? staged.creator.creatoremailSet.map(item => ({
                  label: item.value,
                  value: item.value
                })) : [{ label: "No Creator Selected", value: "" }]}
                closeOnSelect={true}
                onSelect={(option) => {
                  setEmail(option.value)
                }}
              >
                <Button disabled={!sendEmail}>
                  {email ? email : 'Select Email Address...'}
                </Button>
              </SelectMenu>
            </Pane>
          </Pane>
          <Pane>
            <Paragraph marginTop={16} color="#667b7f" size={300}>Note to Creator (optional)</Paragraph>
            <TextareaField
              maxHeight="20vh"
              overflow="auto"
              label=""
              placeholder="Use this space to add context to the statement information seen on the right."
              value={statementNote}
              onChange={(e) => setstatementNote(e.target.value)}
            />
          </Pane>
          <Pane>
            <Pane marginBottom={8} display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" >
              <Paragraph fontWeight="bold" size={300} >ROYALTIES</Paragraph>
            </Pane>
            <Table.Head height={32} backgroundColor="#F4F5F9" >
              <Table.TextCell flexGrow="2" wordBreak="break-word"> <b>PRODUCT</b> </Table.TextCell>
              <Table.TextCell flexGrow="2"><Pane display="flex" alignItems="center"><b>TERMS</b>
                <Tooltip content={<Pane><Paragraph marginLeft={10} size={300}><b>% of list price:</b> % of title's retail price, no matter how much the publisher earns</Paragraph>
                  <Paragraph marginLeft={10} size={300}><b>% of net receipts:</b> % of publisher's income for title, not taking into account expenses</Paragraph>
                  <Paragraph marginLeft={10} size={300}><b>% of net profits:</b> % of title's sales minus expenses</Paragraph>
                  <Paragraph marginLeft={10} size={300}><b>% of true profits/profit sharing:</b> net profits minus publisher's bottom line percentage</Paragraph></Pane>} appearance="card"
                ><HelpIcon color="muted" marginLeft={4} /></Tooltip>
              </Pane></Table.TextCell>
              <Table.TextCell flexGrow="1"><b>BALANCE DUE</b></Table.TextCell>
            </Table.Head>

            {loading ? (<Pane display="flex" alignItems="center" justifyContent="center" height={100}><Spinner size={32} /></Pane>) :
              creator.id ?
                (<Pane overflow="auto" border="default" height="calc(100vh - 572px)">
                  <NotSeeingItems items="royalties" parentItem="royalty statements"/>
                  {royaltys.length > 0 ? (royaltys.map(item => {
                    return (
                      <RoyaltyItemCreator
                        key={"royalty" + item.id}
                        item={item}
                        selectall={allmode}
                        handleAddSelected={handleAddSelected}
                        handleRemoveSelected={handleRemoveSelected}
                        handleAddExcluded={handleAddExcluded}
                      />
                    );
                  })) :
                    (<Pane display="flex" justifyContent="space-around">
                      <Paragraph color="#858480" marginTop={8} size={300}> (SELECTED CREATOR HAS NO ROYALTIES) </Paragraph>
                    </Pane>)
                  }
                </Pane>) :
                (<Pane display="flex" justifyContent="space-around">
                  <Paragraph color="#858480" marginTop={8} size={300}> (NO CREATOR SELECTED) </Paragraph>
                </Pane>)
            }
          </Pane>
        </Pane>
      </Pane>
      <Card id="statement-preview" elevation={1} backgroundColor="white" padding={16} height="calc(100vh - 32px)"
        width="calc(48vw - 102px)">
        <Paragraph size={300} fontWeight="bold" marginBottom={8}>STATEMENT EMAIL PREVIEW</Paragraph>
        <Card overflow="auto" borderStyle="dashed" borderColor="grey" backgroundColor="#f7f9f9" height="calc(100vh - 88px)">
          <Pane backgroundColor="#f7f9f9">
            <Pane marginLeft={24} marginRight={8} paddingY={16}>
              <Paragraph size={400}>Hello {staged.creator ? staged.creator.displayName : "[creator]"},</Paragraph>
              <Paragraph size={400} marginTop={8}>Here is your royalty statement from {currPub.name}. This statement was generated on {todayDate}.</Paragraph>
              {statementNote && <Paragraph size={400} marginTop={16}> <b>Note from Publisher:</b> {statementNote} </Paragraph>}
              <Paragraph size={400} marginTop={8}>Here is your current address on file:
              </Paragraph>
              {staged.creator && formatCreatorAddress(staged.creator).split('\n').map(str => <Paragraph key={`address${str}`}>{str}</Paragraph>)}
              <Paragraph size={400} marginTop={16}><b>STATEMENT SUMMARY </b></Paragraph>

              <RoyaltyStatementSummary startDate={startDate} endDate={endDate} items={selected} currency={currency} />
              <Paragraph size={400} marginTop={16}><b>STATEMENT DETAILS</b></Paragraph>

              {selected.length > 0 &&
                <Pane id="statementItems">
                  {selected.map((item, index) => {
                    return (RoyaltyStatementLineItem({
                      key: "statement" + item.id,
                      item: item,
                      publisherGlobalData: publisherGlobalData,
                      prodTotal: selected.length,
                      idx: index + 1,
                      startDate: startDate,
                      endDate: endDate

                    }))
                  })}
                </Pane>}

              {royaltyTermDefs}

            </Pane>
          </Pane>
        </Card>
      </Card>
    </Pane>
  )
}

export default StatementForm;