import React from "react";
import ReactDOM from 'react-dom';
import {Route, Switch, BrowserRouter, Redirect,} from 'react-router-dom';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import store from './store/store.js'
import { Provider } from 'react-redux'
import App from './App';
import Login from "./views/Login/Login.js";
import AcceptInvite from "./views/AcceptInvite.js";
import reportWebVitals from './reportWebVitals';
import './assets/styles/index.css';

//...
// import * as Sentry from "@sentry/react";

// Sentry.init({
//   dsn: "https://b29aa3e8da966b2daeded4d62bd5707a@o4507947661852672.ingest.us.sentry.io/4507980770181120",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Tracing
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });



const baseUrl = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_GRAPHQL_URL : window.location.protocol + '//' + window.location.hostname

const client = new ApolloClient({
  uri: baseUrl + '/graphql/',
});

const checkAuthentication = () => {
	if (localStorage.getItem('token') !== null) {
    return true;
  } else {
    return false;
  }
}

const checkUserid = () => {
  if (localStorage.getItem('userid') !== null) {
    return localStorage.getItem('userid');
  } else {
    return false;
  }
}

const checkPublisher = () => {
  if (localStorage.getItem('publisher') !== null) {
    return localStorage.getItem('publisher');
  } else {
    return false;
  }
}

const checkPublisherName = () => {
  if (localStorage.getItem('publisher_name') !== null) {
    return localStorage.getItem('publisher_name');
  } else {
    return false;
  }
}

const isAuthenticated = checkAuthentication();
const user = checkUserid();
const publisher = checkPublisher();
const publisher_name = checkPublisherName();

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>

          <Route path="/accept_invite">
            <AcceptInvite />
          </Route>

          <Route path="/">
            {isAuthenticated ? (
              <App isAuthenticated={isAuthenticated} user={user} publisher={publisher} publisherName={publisher_name} baseUrl={baseUrl} />
            ) : (
              <Redirect to="/login" />
            )}
          </Route>
        </Switch>
      </ApolloProvider>
    </Provider>
  </BrowserRouter>
, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
